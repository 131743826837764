import { extendTheme } from '@chakra-ui/react'

const theme = extendTheme({
  fonts: {
    body: '"Poppins", system-ui, sans-serif',
    heading: '"Poppins", Georgia, serif',
    mono: '"Poppins", Menlo, monospace'
  },
  colors: {
    primary: {
      50: '#ffe1ec',
      100: '#ffb1c7',
      200: '#ff7ea1',
      300: '#ff4c7b',
      400: '#ff1a56',
      500: '#e6003c',
      600: '#b4002f',
      700: '#810020',
      800: '#500013',
      900: '#210007'
    }
  }
})

export default theme
