import { useState } from 'react'
import { Box, Button, Container, Link, Stack, Icon, IconButton } from '@chakra-ui/react'
import NextImage from 'next/image'
import { FiMenu, FiX } from 'react-icons/fi'
import { motion } from 'framer-motion'
import NextLink from 'next/link'
import useStore from 'store'

const MotionBox = motion(Box)

const Navbar = () => {
  const [isExpanded, setIsExpanded] = useState(false)
  const toggleExpanded = () => setIsExpanded(!isExpanded)
  const collapseMenu = () => setIsExpanded(false)
  const display = isExpanded ? 'flex' : 'none'
  const toggleContactModal = useStore((state) => state.toggleContactModal)

  return (
    <MotionBox
      paddingY={['0.75rem', '0.75rem', '0.5rem']}
      position="sticky"
      top={0}
      left={0}
      right={0}
      backgroundColor="rgba(255, 255, 255, 0.85)"
      zIndex={1}
      backdropFilter="blur(1rem)"
      initial={{ y: -32 }}
      animate={{ y: 0 }}
      transition={{ duration: 0.4 }}
    >
      <Container maxWidth="container.xl" position="relative">
        <Stack
          direction={['column', 'column', 'row']}
          gap="1rem"
          fontSize="1.25rem"
          fontWeight={500}
          justifyContent="center"
          alignItems={['auto', 'auto', 'center']}
        >
          <Box flex={1}>
            <NextLink href="/" passHref>
              <Link>
                <NextImage src="/logo.svg" width={200} height={40} alt="Medwind Logo" />
              </Link>
            </NextLink>
          </Box>
          <Stack
            display={[display, display, 'flex']}
            direction={['column', 'column', 'row']}
            gridGap="2rem"
            alignItems="center"
          >
            <NextLink href="/" passHref>
              <Link onClick={collapseMenu}>O nas</Link>
            </NextLink>
            <NextLink href="/zespol" passHref>
              <Link onClick={collapseMenu}>Zespół</Link>
            </NextLink>
            <NextLink href="/uslugi" passHref>
              <Link onClick={collapseMenu}>Usługi</Link>
            </NextLink>
            <NextLink href="/cennik" passHref>
              <Link onClick={collapseMenu}>Cennik</Link>
            </NextLink>
            <NextLink href="/vouchery" passHref>
              <Link onClick={collapseMenu}>Vouchery</Link>
            </NextLink>
            <Button
              borderRadius={0}
              size="lg"
              fontSize="1rem"
              colorScheme="black"
              backgroundColor="gray.800"
              onClick={toggleContactModal}
            >
              Umów Wizytę
            </Button>
          </Stack>
        </Stack>
        <IconButton
          display={['inline-flex', 'inline-flex', 'none']}
          position="absolute"
          right="1rem"
          top="-0.25rem"
          onClick={toggleExpanded}
          variant="ghost"
          aria-label="Toggle navigation"
          icon={isExpanded ? <Icon as={FiX} boxSize="1.5rem" /> : <Icon as={FiMenu} boxSize="1.5rem" />}
        />
      </Container>
    </MotionBox>
  )
}

export default Navbar
