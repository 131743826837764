import { Box, Container, Grid, Icon, Link, Stack, Text } from '@chakra-ui/react'
import NextImage from 'next/image'
import { RiFacebookCircleFill } from 'react-icons/ri'
import NextLink from 'next/link'
import useStore from 'store'

const Footer = () => {
  const currentYear = new Date().getFullYear()
  const toggleContactModal = useStore((state) => state.toggleContactModal)

  return (
    <Container maxWidth="container.xl" paddingY={['4rem', '4rem', '6rem']}>
      <Grid gridTemplateColumns={['1fr', '1fr', '2fr 1fr 1fr']} gap="3rem">
        <Stack gap="0.75rem">
          <Box>
            <NextImage src="/logo.svg" width={152} height={29} alt="Medwind Logo" />
          </Box>
          <Link href="tel:+48618306030">61 830 60 30</Link>
          <Link href="mailto:biuromedwind@gmail.com">biuromedwind@gmail.com</Link>
          <Text>ul. Ostatnia 23, 60-102 Poznań</Text>
          <a href="https://www.facebook.com/MedwindPoznan" target="_blank" rel="noreferrer">
            <Icon as={RiFacebookCircleFill} boxSize="2rem" />
          </a>
        </Stack>
        <Stack gap="0.75rem">
          <Text fontWeight={500}>Centrum</Text>
          <NextLink href="/zespol" passHref>
            <Link>Zespół</Link>
          </NextLink>
          <NextLink href="/uslugi" passHref>
            <Link>Usługi</Link>
          </NextLink>
          <NextLink href="/cennik" passHref>
            <Link>Cennik</Link>
          </NextLink>
          <NextLink href="/vouchery" passHref>
            <Link>Vouchery</Link>
          </NextLink>
          <Link onClick={toggleContactModal}>Kontakt</Link>
        </Stack>
        <Stack gap="0.75rem">
          <Text fontWeight={500}>Dokumenty</Text>
          <NextLink href="/dokument/rodo" passHref>
            <Link>RODO</Link>
          </NextLink>
          <NextLink href="/dokument/polityka-prywatnosci" passHref>
            <Link>Polityka prywatności</Link>
          </NextLink>
          <NextLink href="/dokument/regulamin" passHref>
            <Link>Regulamin</Link>
          </NextLink>
        </Stack>
      </Grid>
      <Text textAlign="center" marginTop="4rem">
        © {currentYear} Medwind. Wykonanie -{' '}
        <a href="https://mvr.studio" target="_blank" rel="noreferrer">
          MVR Studio
        </a>
      </Text>
    </Container>
  )
}

export default Footer
