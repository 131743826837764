import {
  Button,
  Heading,
  HStack,
  Icon,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text
} from '@chakra-ui/react'
import { FiPhone } from 'react-icons/fi'

interface ContactModalProps {
  isOpen: boolean
  onClose: () => void
}

const ContactModal = ({ isOpen, onClose }: ContactModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Umów Wizytę</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack>
            <HStack>
              <Icon as={FiPhone} />
              <Heading size="md" color="gray.700">
                Telefonicznie
              </Heading>
            </HStack>
            <Text>
              Zadzwoń pod numer{' '}
              <Link href="tel:618306030" fontWeight="bold">
                61 830 60 30
              </Link>
            </Text>
          </Stack>
        </ModalBody>
        <ModalFooter />
      </ModalContent>
    </Modal>
  )
}

export default ContactModal
