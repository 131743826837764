import create from 'zustand'

interface State {
  isContactModalOpen: boolean
  toggleContactModal: () => void
}

const useStore = create<State>((set) => ({
  isContactModalOpen: false,
  toggleContactModal: () =>
    set((state) => ({
      isContactModalOpen: !state.isContactModalOpen
    }))
}))

export default useStore
