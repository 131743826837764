import { Box, Flex } from '@chakra-ui/react'
import useStore from 'store'
import shallow from 'zustand/shallow'
import ContactModal from './ContactModal'
import Footer from './Footer'
import Navbar from './Navbar'

interface LayoutProps {
  children: React.ReactNode
}

const Layout = ({ children }: LayoutProps) => {
  const { isContactModalOpen, toggleContactModal } = useStore(
    (state) => ({
      isContactModalOpen: state.isContactModalOpen,
      toggleContactModal: state.toggleContactModal
    }),
    shallow
  )

  return (
    <Flex direction="column" paddingTop="2rem">
      <ContactModal isOpen={isContactModalOpen} onClose={toggleContactModal} />
      <Navbar />
      <Box flex={1}>{children}</Box>
      <Footer />
    </Flex>
  )
}

export default Layout
